// @flow
import { Component } from "react";
import styled from "styled-components";
import { Spinner } from "@nested/ui";
import { NotFound } from "../../pages/NotFound";

const Wrapper = styled.div`
  width: 100%;
  margin: auto;
  padding-top: 50px;
  padding-bottom: 50px;
  text-align: center;
`;

type Props<T> = {
  spinner?: React$Node,
  content: ?RemoteContent<T>,
  children(content: T): React$Node,
};

class Loader<T> extends Component<Props<T>> {
  render() {
    const { children, content, spinner } = this.props;
    if (!content || (!content.error && !content.fetched)) {
      return (
        spinner || (
          <Wrapper>
            <Spinner />
          </Wrapper>
        )
      );
    }

    if (content.error) {
      throw content.error;
    }

    if (content.notFound) {
      return <NotFound />;
    }

    if (content.content === undefined) {
      throw new Error("Loader finished but content is undefined");
    }

    return children(content.content);
  }
}

export { Loader };
