// @flow
import React from "react";
import { useSelector } from "react-redux";
import { Sentry } from "@nested/isomorphic-sentry";
import { Footer } from "@nested/brand";
import { NotFound } from "@nested/brand/src/pages/NotFound";
import { type ContextRouter } from "react-router";
import { Loader } from "../../components/Loader/Loader";
import { Slice } from "./Slice";
import { MetaTags } from "./MetaTags";
import { fetchMarketingPageSaga } from "./fetchData";
import { PreviewMode } from "./PreviewMode";

// TODO: replace these guys
const ServerError = () => "Internal server error";

type Props = {
  content: ?RemoteContent<PrismicCustomPage>,
};

type State = {
  hasError: boolean,
};

export class CustomComponent extends React.Component<Props, State> {
  state = { hasError: false };

  componentDidCatch(e: Error, info: any) {
    Sentry.captureException(e, { info });
    this.setState({ hasError: true });
  }

  render() {
    const { hasError } = this.state;
    const { content } = this.props;

    if (hasError) {
      return <ServerError />;
    }

    if (content?.notFound) {
      return (
        <div>
          <PreviewMode />
          <NotFound />
        </div>
      );
    }
    return <Page content={content} />;
  }
}

export const Page = ({ content }: Props) => (
  <Loader spinner={<div data-test="loading-div" />} content={content}>
    {(page) => (
      <div key={page.uid}>
        <MetaTags page={page} />
        <PreviewMode />
        {page.data.body.map((slice, index) => (
          <Slice key={index}>{slice}</Slice>
        ))}
      </div>
    )}
  </Loader>
);

export const Custom = ({
  match: {
    params: { item },
  },
}: ContextRouter) => {
  const pageContent = useSelector(
    (state) => state.customPages[item || "default"],
  );
  const showFooter = !pageContent?.content?.data?.hide_footer;

  return (
    <>
      <CustomComponent content={pageContent} />
      {showFooter && <Footer />}
    </>
  );
};

Custom.fetchData = fetchMarketingPageSaga;

export const UniversalRender = Custom;
