// @flow
const GET_PAGE_SUCCESS = "pages/Custom/GET_PAGE_SUCCESS";
const GET_PAGE_ERROR = "pages/Custom/GET_PAGE_ERROR";
const PAGE_NOT_FOUND = "pages/Custom/PAGE_NOT_FOUND";

type State = {};

type GetPageSuccessAction = {
  type: typeof GET_PAGE_SUCCESS,
  payload: {
    id: string,
    content: PrismicCustomPage,
  },
};

type GetPageErrorAction = {
  type: typeof GET_PAGE_ERROR,
  payload: {
    id: string,
    error: Error,
  },
};

type PageNotFoundAction = {
  type: typeof PAGE_NOT_FOUND,
  payload: {
    id: string,
  },
};

export const getPageSuccess = (
  id: string,
  content: PrismicCustomPage,
): GetPageSuccessAction => ({
  type: GET_PAGE_SUCCESS,
  payload: {
    id,
    content,
  },
});

export const getPageError = (id: string, error: Error): GetPageErrorAction => ({
  type: GET_PAGE_ERROR,
  payload: {
    id,
    error,
  },
});

export const pageNotFound = (id: string): PageNotFoundAction => ({
  type: PAGE_NOT_FOUND,
  payload: {
    id,
  },
});

type Action = GetPageSuccessAction | GetPageErrorAction | PageNotFoundAction;

export const reducer = (state: State = {}, action: Action) => {
  switch (action.type) {
    case GET_PAGE_SUCCESS:
      return {
        ...state,
        [action.payload.id]: {
          fetched: true,
          content: action.payload.content,
        },
      };
    case GET_PAGE_ERROR:
      return {
        ...state,
        [action.payload.id]: {
          fetched: false,
          error: action.payload.error,
        },
      };
    case PAGE_NOT_FOUND:
      return {
        ...state,
        [action.payload.id]: {
          fetched: true,
          notFound: true,
        },
      };
    default:
      return state;
  }
};
