import styled from "styled-components";

const StyledSpinner = styled.div`
  display: flex;
  justify-content: center;
  margin: 0;
  text-align: center;
  div {
    width: 15px;
    height: 15px;
    background-color: #333;
    border-radius: 100%;
    display: inline-block;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  }
  .bounce1 {
    animation-delay: -0.32s;
  }
  .bounce2 {
    animation-delay: -0.16s;
  }
  @keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
      transform: scale(0);
    }
    40% {
      transform: scale(1);
    }
  }
`;

export const Spinner = () => (
  <StyledSpinner>
    <div className="bounce1" />
    <div className="bounce2" />
    <div className="bounce3" />
  </StyledSpinner>
);
