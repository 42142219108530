// @flow
import { Component } from "react";
import styled from "styled-components";
import { Sentry } from "@nested/isomorphic-sentry";

const ErrorWrapper = styled.section`
  padding-top: 50px;
  padding-bottom: 50px;
  width: 100%;
  padding-left: 35px;
  padding-right: 35px;
`;

const Error = styled.div`
  padding-top: 50px;
  padding-bottom: 50px;
  color: red;
  max-width: 1200px;
  width: 100%;
  margin: auto;
`;

type ErrorProps = {
  sliceName: string,
  error: ?Error,
  info: any,
};

const SliceError = ({ sliceName, error, info }: ErrorProps) => (
  <ErrorWrapper>
    {process.env.NODE_ENV === "production" ? null : (
      <Error>
        <h1>
          Error in slice {sliceName}: {error?.message}
        </h1>
        <p>{info && info.componentStack.split("\n").map((e) => <p>{e}</p>)}</p>
      </Error>
    )}
  </ErrorWrapper>
);

type Props = {
  sliceName: string,
  children: React$Node,
};

type State = {
  hasError: boolean,
  error?: Error,
  info?: {
    componentStack: string,
  },
};

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: Error, info: Object) {
    const { sliceName } = this.props;
    this.setState({ hasError: true, error, info });
    Sentry.captureException(error, {
      slice: sliceName,
      info: JSON.stringify(info),
    });
  }

  render() {
    const { children, sliceName } = this.props;
    const { error, hasError, info } = this.state;
    return hasError ? (
      <SliceError sliceName={sliceName} error={error} info={info} />
    ) : (
      children
    );
  }
}

export { ErrorBoundary };
