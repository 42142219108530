// @flow
/* eslint-disable react/no-unescaped-entities */
import { Component } from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import { media, ErrorMessage } from "@nested/ui";
import { PreviewMode } from "../../components/PreviewMode/PreviewMode";

const Wrapper = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 72px 0px;
  min-height: calc(75vh);
  ${media.tablet`
    padding: 110px 0px;
  `};
`;

type Props = {
  staticContext?: Object,
};

class NotFoundComponent extends Component<Props> {
  constructor(props: Props) {
    super(props);
    if (props.staticContext) {
      /* eslint-disable-next-line */
      props.staticContext.status = 404;
    }
  }

  render() {
    return (
      <>
        <PreviewMode />
        <Wrapper data-test="not-found">
          <ErrorMessage
            icon="lost.svg"
            title="The page you were looking for doesn't exist"
            message="You may have mistyped the address or the page may have moved."
          />
        </Wrapper>
      </>
    );
  }
}

export const NotFound = withRouter(NotFoundComponent);

export const UniversalRender = NotFound;
