// @flow
import { css } from "styled-components";
import { addQueryParams } from "@nested/utils";
import queryString from "query-string";
import { getConfig } from "@nested/config";

const { IMGIX_URI } = getConfig();

const createSrcSet = (src) => {
  if (
    !src.startsWith(IMGIX_URI) &&
    !src.startsWith("https://images.prismic.io")
  ) {
    // If the image is not served by imgix (either ours or Prismic's), don't create a srcset
    return undefined;
  }
  return `${addQueryParams(src, { dpr: 1 })}, ${addQueryParams(src, {
    // dpr tells imgix about the user's device pixel ratio and allows it to scale up the image for best possible output quality
    dpr: 2,
  })} 2x`;
};

type ResponsiveImageProps = {
  alt?: ?string,
  src?: string,
  className?: string,
  inView?: boolean,
};

const getUrlParam = (src, param) => {
  const parsed = queryString.parse(src);
  return parsed[param] ? parsed[param] : null;
};

const createAspectCss = (src) => {
  const width = getUrlParam(src, "w");
  const height = getUrlParam(src, "h");
  if (width && height)
    return css`
      padding-top: calc(100% * ${height} / ${width});
    `;
  return null;
};

export const ResponsiveImage = ({
  alt = "",
  src = "",
  className,
  inView = true,
}: ResponsiveImageProps) =>
  inView ? (
    <img className={className} alt={alt} src={src} srcSet={createSrcSet(src)} />
  ) : (
    <div className={className} css={createAspectCss(src)} />
  );
