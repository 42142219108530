import { useRef } from "react";
import { css } from "styled-components";
import { Button } from "@nested/component-library";
import { useSelector } from "react-redux";
import { getConfig } from "@nested/config";

const { COOKIE_DOMAIN, MARKETING_SITE_URI } = getConfig();
const previewCookie = "io.prismic.preview";
const expiryDate = "Thu, 01 Jan 1970 00:00:01 GMT";
const path = "/";

const previewMode = css`
  position: fixed;
  top: 20px;
  left: 20px;
  width: 100%;
  max-width: 200px;
  text-align: center;
  padding: 20px;
  background-color: white;
  box-shadow: 0 2px 4px 0 rgba(34, 34, 34, 0.3);
  border-radius: 4px;
  z-index: 100;
`;

const invisibleInput = css`
  position: absolute;
  opacity: 0;
  pointer-events: none;
`;

export const PreviewMode = () => {
  const prismicLinkRef = useRef();
  const prismicInfo = useSelector((state) => state.cms?.version);
  if (!prismicInfo?.previewMode) {
    return null;
  }

  const copyPreviewInfo = () => {
    const input = prismicLinkRef.current;
    if (!input) {
      return;
    }
    input.select();
    document.execCommand("copy");
    // eslint-disable-next-line
    window.alert("Shareable preview link copied to your clipboard");
  };

  const exitPreviewMode = () => {
    document.cookie = `${previewCookie}=; Expires=${expiryDate}; Domain=${COOKIE_DOMAIN}; Path=${path};`;
    window.location.reload();
  };

  return (
    <div css={previewMode}>
      <p css="margin-top: 0px;">You&apos;re in preview mode!</p>
      <input
        type="text"
        css={invisibleInput}
        ref={prismicLinkRef}
        value={`${MARKETING_SITE_URI}/preview?token=${prismicInfo.ref}`}
      />
      <p>
        <Button onClick={copyPreviewInfo}>Share preview link</Button>
      </p>
      <Button onClick={exitPreviewMode} type="accent">
        Exit preview mode
      </Button>
    </div>
  );
};
