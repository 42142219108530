// @flow
import styled, { css } from "styled-components";
import { withRouter } from "react-router-dom";
import { SmartLink } from "@nested/component-library";

import {
  sectionHeading,
  largeHeading,
  smallHeading,
  extraLargeHeading,
  regularHeading,
} from "../components/Typography/Headings";
import { media } from "../media";
import { ResponsiveImage } from "../components/ResponsiveImage";

const textStyles = css`
  text-align: center;
  ${media.desktop`
    text-align: left;
  `}
`;

const colouredTextStyle = css`
  color: ${({ theme }) => theme.palette.terracotta100};
  font-family: inherit;
`;

const backgroundStyle = css`
  ${textStyles};
  padding: 40px 20px;
  ${media.tablet`
    padding: 60px 20px;
  `};
  ${media.desktop`
    padding: 100px 20px;
  `};
  background-color: ${({ theme }) => theme.palette.hague};
  box-sizing: border-box;
`;

const wrapperStyle = css`
  margin: 0 auto;
  align-items: stretch;
  ${media.desktop`
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1100px;
  `};
  width: 100%;
`;

const ContentWrapper = styled.div`
  width: 100%;
  ${media.tablet`
    max-width: 534px;
    margin: 0 auto;
  `};
  ${media.desktop`
    margin: 0;
    width: calc(50% - 20px);
  `};
`;

const SectionHeading = styled.h2`
  ${sectionHeading};
  opacity: 0.8;
  font-size: 12px;
  color: white;
  ${media.desktop`
  font-size: 20px;
  `};
`;

const Title = styled.h1`
  margin: 10px 0;
  ${largeHeading}
  ${media.tablet`
    ${extraLargeHeading}
    color: white;
  `};
  ${media.desktop`
    ${extraLargeHeading}
    margin: 20px 0;
    color: white;
  `};
  color: white;
`;

const SubTitle = styled.h3`
  ${smallHeading};
  ${media.tablet`
    ${regularHeading}
    margin: 10px 0;
    padding: 0 40px;
    color: white;
  `};
  ${media.desktop`
    ${regularHeading}
    padding: 0;
    max-width: 90%;
    color: white;
  `};
  margin: 0;
  color: white;
`;

const LinksList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-content: space-between;
  line-height: 30px;
  list-style-type: none;
  padding: 0;
  margin: 30px auto;
  ${media.tablet`
    display: block;
  `};
  ${media.desktop`
    display: flex;
    flex-direction: column;
    margin-left: 5px;
    line-height: 34px;
  `};
  color: white;
`;

const ListItem = styled.li`
  font-weight: 500;
  display: inline;
  ${media.tablet`
    :not(:first-child)::before {
      content: "•";
      color: ${({ theme }) => theme.palette.terracotta100};
      margin: 0 12px 0 12px;
    }
  `};
  ${media.desktop`
    ::before {
      content: "•";
      color: ${({ theme }) => theme.palette.terracotta100};
      margin: 0 12px 0 0 !important;
    }
  `};
`;

const ImageWrapper = styled.div`
  ${media.tablet`
    max-width: 534px;
  `};
  ${media.desktop`
    width: calc(50% - 20px);
    max-width: none;
    margin: 0;
  `};
  margin: 30px auto 0;
`;

const imageStyle = css`
  width: 100%;
`;

type Props = {
  staticContext?: Object,
};

export const NotFoundWithoutStatus = ({ staticContext }: Props) => {
  if (staticContext) {
    // server only
    staticContext.status = 404;
  }

  return (
    <div css={backgroundStyle} data-test="not-found">
      <div css={wrapperStyle}>
        <ContentWrapper>
          <SectionHeading>404 page not found</SectionHeading>
          <Title>This page has been abducted</Title>
          <SubTitle>
            <span css={colouredTextStyle}>I want to believe</span> that my dream
            house is out there
          </SubTitle>
          <LinksList>
            <ListItem>
              <SmartLink to="/">Sell with Nested</SmartLink>
            </ListItem>
            <ListItem>
              <SmartLink to="/for-sale">Homes for sale</SmartLink>
            </ListItem>
            <ListItem>
              <SmartLink to="/how-it-works">How it works</SmartLink>
            </ListItem>
          </LinksList>
        </ContentWrapper>
        <ImageWrapper>
          <ResponsiveImage
            css={imageStyle}
            src="https://images.prismic.io/nested/2d9fed94-f073-4751-ab23-e127b6ad418f_404.png?auto=compress,format"
            alt="Not found"
          />
        </ImageWrapper>
      </div>
    </div>
  );
};

export const NotFound = withRouter(NotFoundWithoutStatus);
