// @flow
import { Helmet } from "react-helmet";

type Props = {
  page: PrismicCustomPage,
};

const MetaTags = ({
  page: {
    uid,
    data: {
      page_title: pageTitle,
      page_description: pageDescription,
      page_image: pageImage,
      exclude_from_search_engines: excludeFromSearch,
    },
  },
}: Props) => (
  <Helmet>
    {(uid.startsWith("wip-") || excludeFromSearch === "Yes") && (
      <meta name="robots" content="noindex" />
    )}
    {pageTitle && <title>{pageTitle}</title>}
    {pageTitle && <meta property="og:title" content={pageTitle} />}
    {pageDescription && <meta name="description" content={pageDescription} />}
    {pageDescription && (
      <meta property="og:description" content={pageDescription} />
    )}
    {pageImage?.url && <meta property="og:image" content={pageImage?.url} />}
    <meta
      property="og:url"
      content={`https://nested.com/${uid === "default" ? "" : uid}`}
    />
    <link
      rel="canonical"
      href={`https://nested.com/${uid === "default" ? "" : uid}`}
    />
    <meta property="og:type" content="article" />
  </Helmet>
);

export { MetaTags };
