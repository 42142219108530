// @flow
import { camelCase } from "lodash";
import { slices } from "@nested/brand/slices";
import { componentName } from "../../utils/text";
import { ErrorBoundary } from "./SliceErrorBoundary";

const prismicObjectToProps = (obj) =>
  Object.keys(obj).reduce(
    (acc, k) => ({
      ...acc,
      [camelCase(k)]: obj[k] === null ? undefined : obj[k],
    }),
    {},
  );

export const getSliceComponent = ({
  slice_type: sliceType,
}: PrismicSlice): ?React$ComponentType<any> => slices[componentName(sliceType)];

const getSliceProps = ({ primary, items }) => {
  const props = prismicObjectToProps(primary);
  const repeat = items.map(prismicObjectToProps);
  return {
    ...props,
    repeat,
  };
};

const createElementFromSlice = (slice) => {
  const Component = getSliceComponent(slice);
  if (!Component) {
    return null;
  }

  const props = getSliceProps(slice);

  return <Component {...props} />;
};

type Props = {
  children: PrismicSlice,
};

export const Slice = ({ children }: Props) => (
  <ErrorBoundary sliceName={children.slice_type}>
    {createElementFromSlice(children)}
  </ErrorBoundary>
);
