// @flow
import styled from "styled-components";
import { connect } from "react-redux";
import { addClickHandler } from "./addClickHandler";

const Wrapper = styled.div`
  position: fixed;
  top: 20px;
  left: 20px;
  text-align: center;
  padding: 10px;
  z-index: 100;
  width: 200px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(34, 34, 34, 0.1);
`;

const ExitButton = styled.button`
  display: block;
  margin: auto;
  margin-top: 10px;
  border-radius: 4px;
  border: none;
  background-color: hsl(20, 100%, 86%);
  padding: 5px 10px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 14px;
  line-height: 1.3;
  cursor: pointer;
`;

type Props = {
  previewMode: boolean,
};

export const PreviewModeComponent = ({ previewMode }: Props) => {
  if (!previewMode) {
    return null;
  }
  return (
    <Wrapper>
      You&apos;re in preview mode!
      <ExitButton id="exit-preview-mode-button" ref={addClickHandler}>
        Click here to exit
      </ExitButton>
    </Wrapper>
  );
};

PreviewModeComponent.displayName = "PreviewMode";

const mapStateToProps = (state) => ({
  previewMode: state.cms?.version?.previewMode,
});

export const PreviewMode = connect<Props, {||}, _, _, _, _>(mapStateToProps)(
  PreviewModeComponent,
);
