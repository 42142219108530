/*
 * @flow
 *
 * These sagas are split from the reducer and actions to prevent webpack from
 * loading every single slice and the Prismic library into the main bundle when
 * creating the root reducer
 */
import { type Saga } from "redux-saga";
import { type Match } from "react-router";
import { all, select, call, put, getContext } from "redux-saga/effects";
import { PrismicResourceNotFound } from "../../utils/PrismicClient/PrismicClient";
import { getSliceComponent } from "./Slice";
import { getPageSuccess, pageNotFound, getPageError } from "./ducks";

const selectPage = ({ customPages }, id) => customPages[id];

const getPageId = (item, url) => {
  if (url.startsWith("/technology")) {
    return `technology-${item || "default"}`;
  }
  return item || "default";
};

export function* fetchMarketingPageSaga({
  params: { item },
  url,
}: Match<{ item: ?string }>): Saga<void> {
  const id = getPageId(item, url);
  const page = yield select(selectPage, id);
  if (page?.fetched) {
    return;
  }

  try {
    const prismicClient = yield getContext("prismicClient");
    const result = yield call(prismicClient.getPage, id, "marketing_page");
    yield put(getPageSuccess(id, result));
    yield all(result.data.body.map((slice) => call(fetchSliceData, slice)));
  } catch (e) {
    if (
      e instanceof PrismicResourceNotFound ||
      e.message === "Page not found" ||
      e.status === 404
    ) {
      yield put(pageNotFound(id));
    } else {
      yield put(getPageError(id, e));
    }
  }
}

export function* fetchSliceData(slice: PrismicSlice): Saga<void> {
  const universalSliceComponent = yield call(getSliceComponent, slice);
  const sliceComponent = yield call(universalSliceComponent.preload);
  const fetchFunction = sliceComponent?.fetchData;
  if (fetchFunction) {
    yield call(fetchFunction);
  }
}
