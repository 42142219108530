// @flow
import universal from "react-universal-component";
import { UniversalComponentError } from "./src/components/UniversalComponentError";

const sliceNames: Array<string> = [
  "AvmLandingSlice",
  "Banner",
  "BannerCta",
  "BannerWithImage",
  "Bookend",
  "BuyingAndSelling",
  "ComparisonTable",
  "Cookies",
  "CurrentJobs",
  "CustomerStoriesBodyCopy",
  "CustomerStoriesInlineImage",
  "CustomerTestimonial",
  "EmailCaptureHero",
  "EmailSignup",
  "Faqs",
  "FeesHero",
  "ForSaleList",
  "HalfImageHalfText",
  "HalfVideoHalfText",
  "HomepageHero",
  "HomepageHeroVideo",
  "HorizontalLine",
  "InThePress",
  "LargeImageSmallText",
  "LocalLandingPageBranchInfo",
  "LocalLandingPageHero",
  "LocalLandingPageZeroFeesBanner",
  "MeetTheTeam",
  "PartnershipsCalculator",
  "PropertyCarousel",
  "Quote",
  "RichText",
  "StaffProfile",
  "StampDutyCalculator",
  "TechCareersHero",
  "EmployeeQuote",
  "ThreeColumnCta",
  "Timeline",
  "TrustpilotCarousel",
  "Values",
];

export const slices = sliceNames.reduce(
  (acc, name) => ({
    ...acc,
    // $FlowFixMe this works but flow hates it
    [name]: universal(import(`./src/slices/${name}/index`), {
      error: UniversalComponentError,
      ignoreBabelRename: true,
      key: "UniversalRender",
      loading: () => null,
    }),
  }),
  {},
);
