// @flow
import { getConfig } from "@nested/config";

const { COOKIE_DOMAIN } = getConfig();

const previewCookie = "io.prismic.preview";
const expiryDate = "Thu, 01 Jan 1970 00:00:01 GMT";
const path = "/";

const addClickHandler = (button: HTMLElement | null) => {
  if (button) {
    button.addEventListener("click", () => {
      document.cookie = `${previewCookie}=; Expires=${expiryDate}; Domain=${COOKIE_DOMAIN}; Path=${path};`;
      window.location.reload();
    });
  }
};

export { addClickHandler };
