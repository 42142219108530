// @flow
import { Component } from "react";
import { Sentry } from "@nested/isomorphic-sentry";
import { getConfig } from "@nested/config";

const { RELEASE_VERSION } = getConfig();

type Props = {
  error: Error,
};

class UniversalComponentError extends Component<Props> {
  componentDidMount() {
    const { error } = this.props;
    Sentry.captureException(error);
    if (error && RELEASE_VERSION !== "production") {
      console.error("Error loading component: ", error); // eslint-disable-line no-console
    }
  }

  render() {
    return null;
  }
}

export { UniversalComponentError };
